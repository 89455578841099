import React from "react"
import ContentWithBgLayout from "../components/contentWithBgLayout"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import LoginContent from "../components/loginContent"
import Seo from "../components/seo"

const SignInPage = () => {
    return (
        <Layout>
            <Seo title="Login" />
            <NavBar whiteNav bookNow={false} />
            <ContentWithBgLayout>
                <LoginContent />
            </ContentWithBgLayout>
            <Footer />
        </Layout>
    )
}

export default SignInPage
