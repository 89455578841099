import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import fetchQuery from "../utils/fetchQuery"

const LoginContent = () => {
    const rememberCheckValue =
        (typeof window !== "undefined" && window.localStorage.remember !== undefined
            ? window.localStorage.remember === "true"
            : undefined) ?? true
    const [checkboxChecked, setCheckboxChecked] = useState(rememberCheckValue)
    const [emailField, setEmailField] = useState(typeof window !== "undefined" && window.localStorage.rememberEmail)
    const [passwordField, setPasswordField] = useState("")
    const [token, setToken] = useState(typeof window !== "undefined" && window.localStorage.token)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)

    useEffect(() => {
        if (token) navigate("/booking")
    }, [token])

    useEffect(() => {
        typeof window !== "undefined" && window.localStorage.setItem("remember", checkboxChecked)
        if (!checkboxChecked) typeof window !== "undefined" && window.localStorage.setItem("rememberEmail", "")
    }, [checkboxChecked])

    const submit = async () => {
        setErrorMsg(null)
        if (checkboxChecked) {
            typeof window !== "undefined" && window.localStorage.setItem("rememberEmail", emailField)
        } else {
            typeof window !== "undefined" && window.localStorage.setItem("rememberEmail", "")
        }

        await fetchQuery({
            method: "POST",
            endPoint: "auth/login",
            body: {
                emailAddress: emailField,
                password: passwordField
            },
            successCallback: response => {
                if (response.type === 2000) {
                    typeof window !== "undefined" && window.localStorage.setItem("token", response.token)
                    setToken(response.token)
                } else {
                    setErrors({
                        email: "Invalid credentials",
                        password: "Invalid credentials"
                    })
                }
                setIsLoading(false)
            },
            failCallback: () => {
                setIsLoading(false)
                setErrorMsg("Something went wrong. Please reload the page and try again.")
            }
        })
    }

    const onLogin = () => {
        const newErrors = {}

        setIsLoading(true)

        if (!emailField.length) {
            newErrors.email = "Required"
        } else {
            let re = /\S+@\S+\.\S+/
            if (!re.test(emailField)) {
                newErrors.email = "Example: name@example.com"
            }
        }
        if (!passwordField.length) {
            newErrors.password = "Required"
        }

        if (!Object.keys(newErrors).length) {
            submit()
            setErrors({})
        } else {
            setErrors(newErrors)
            setIsLoading(false)
        }
    }

    return (
        <div className="row">
            <div className="col paddingy-special d-flex flex-column align-items-center">
                <div className="col d-flex flex-column align-items-center text-center">
                    <h2>Welcome back!</h2>
                    <p>Enter your email and password to access your account.</p>
                </div>
                <div className="login-container">
                    <div className="row gy-3">
                        <div className="col-12">
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className={`form-control ${errors.email ? "error" : ""} ${
                                        emailField ? "color-purple-border" : ""
                                    }`}
                                    id="email"
                                    placeholder="Email"
                                    value={emailField}
                                    onChange={e => setEmailField(e.target.value)}
                                />
                                <label className={`${emailField ? "color-purple" : ""}`} htmlFor="email">
                                    Email
                                </label>
                                {errors.email && <span className="form-error-message">{errors.email}</span>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <input
                                    type="password"
                                    className={`form-control ${errors.password ? "error" : ""} ${
                                        passwordField ? "color-purple-border" : ""
                                    }`}
                                    id="password"
                                    placeholder="Password"
                                    value={passwordField}
                                    onChange={e => setPasswordField(e.target.value)}
                                />
                                <label className={`${passwordField ? "color-purple" : ""}`} htmlFor="password">
                                    Password
                                </label>
                                {errors.password && <span className="form-error-message">{errors.password}</span>}
                                {errorMsg && <span className="form-error-message">{errorMsg}</span>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked"
                                    checked={checkboxChecked}
                                    onChange={() => setCheckboxChecked(!checkboxChecked)}
                                />
                                <label className="form-check-label disclaimer" htmlFor="flexCheckChecked">
                                    Remember me
                                </label>
                            </div>
                        </div>
                        <div className="col-6 text-end">
                            <Link to="/reset" className="text-decoration-none font-size-forgot">
                                Forgot password?
                            </Link>
                        </div>

                        <div className="col-12 d-flex justify-content-center mt-md-4 mt-3">
                            <button disabled={isLoading} onClick={onLogin} className="button fill light-background w-sign-in">
                                <span>sign in</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginContent
